export default {
	value: [
		{
			field: "id",
			hide: true,
		},
		{
			field: "check",
			headerName: "",
			checkboxSelection: true,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			minWidth: 50,
			maxWidth: 50,
			flex: 0,
		},
		{
			field: "name",
			headerName: "Имя",
			wrapText: true,
			sortable: true,
			minWidth: 255,
		},
		{
			field: "status",
			headerName: "Статус",
			minWidth: 90,
			flex: 0,
		},
		{
			field: "id",
			headerName: "",
			flex: 0,
			cellRenderer: "ButtonPass",
			cellStyle: {justifyContent: "flex-end"}
		},
	]
}