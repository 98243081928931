<template>
  <div class="product-group-table">
    <div v-if="!isPreloaderLoad" class="flex justify-content-center card home-block">

      <div class="display--flex justify-content-between group-header">
        <h4 class="group-name">
          {{ route.params.id === 'all' ? 'Все сотрудники' : groupName }}
        </h4>
        <div class="tools">
          <button
              v-if="getRight('downloadReports') && reportType"
              @click="getReports"
              class="btn btn-dark mr-10"
          >
            Скачать все отчеты
            <i class="fas fa-box-archive"></i>
          </button>
          <router-link v-if="route.params.id !== 'all'"
                       :to="`/group/edit/${route.params.id}`"
                       class="btn btn-accent">Редактировать
          </router-link>
        </div>
      </div>
      <small class="text-left report-name">({{ reportType ? reportType : courseName }})</small>

      <div class="display--flex justify-content-between about-box">
        <div v-if="checkedList.length <= productCountFree" class="display--flex">
          <p>Выбрано <span>{{ checkedList.length }}</span></p>
          <p class="ml-10">Доступно <span>{{ productCountFree }}</span></p>
        </div>
        <div v-if="checkedList.length > productCountFree" class="display--flex align-item-center ml-10">
          <img src="../../../src/assets/images/warning.svg" alt="warning"/>
          <span class="warning-text ml-10">
            В наличии {{ productCountFree + " " + morph(productCountFree, route.params.product) }}.
            Требуется для выдачи дополнительно {{
              checkedList.length - productCountFree + " " + morph((checkedList.length - productCountFree), route.params.product)
            }}.
          </span>
          <button class="btn btn-w ml-10" @click="showConsultModal">Оформить заказ</button>
        </div>
      </div>
      <div class="card-body" v-if="groupUsers && !isPreloaderLoad">
        <div class="display--flex align-item-center justify-content-between">
          <div class="options-ag display--flex align-item-center">
            <div class="select-count">
              <custom-select
                  :labelWidth="40"
                  name="rowsCount"
                  v-model="countOnPage"
                  label="Кол-во пользователей на странице"
                  :options="[5, 10, 15, 20, 50, 75]"
              />
            </div>
            <control-visible-columns :name="columnsName" :columnApi="columnApi"/>
          </div>
          <router-link v-if="showAnalyticsBtn"
                       to="/analytics"
                       class="btn btn-accent">Аналитика
          </router-link>
        </div>
        <div v-if="productGroup">
          <ag-grid-vue
              :columnDefs="columnDefs.value"
              :rowData="rowData"
              :rowHeight="70"
              rowSelection="multiple"
              :headerHeight="90"
              :enableCellTextSelection="true"
              :ensureDomOrder="true"
              :pagination="true"
              :paginationPageSize="countOnPage"
              :defaultColDef="defaultColDef"
              :suppressRowHoverHighlight="true"
              :suppressPaginationPanel="true"
              :suppressRowClickSelection="true"
              :unSortIcon="true"
              :alwaysMultiSort="true"
              :suppressRowTransform="true"
              @row-selected="onRowSelected"
              style="width: 100%"
              domLayout="autoHeight"
              @grid-ready="onGridReady"
              suppressSizeToFit="true"
              @first-data-rendered="onFirstDataRendered"
          >
          </ag-grid-vue>
          <table-pagination
              :gridApi="gridApi"
              :total="gridApi ? gridApi.paginationGetTotalPages() : 0"
              :countOnPage="countOnPage"
          />
        </div>
        <div v-else class="table-preloader">
          <preloader/>
        </div>
      </div>
      <footer>
        <div class="footer__box">
          <router-link
              to="/staff/edit"
              class="btn btn-w footer__box-btn">Добавить сотрудника
          </router-link>
          <button :disabled="!checkedList.length || checkedList.length > productCountFree"
                  class="btn btn-w footer__box-btn"
                  @click="showModalAppened">Выдать доступ
          </button>
          <button :disabled="!checkedList.length"
                  class="btn btn-w footer__box-btn"
                  @click="showModalRemove">Отменить доступ
          </button>
          <button :disabled="!checkedList.length"
                  v-if="isTestProductPage"
                  class="btn btn-w footer__box-btn"
                  @click="remindAboutTest">Напомнить
          </button>

          <Popper v-if="route.params.id !== 'all'" placement="top-start" arrow class="products-popper">
            <button class="btn btn-w footer__box-btn">Другое</button>
            <template #content>
              <ul>
                <li class="display--flex justify-content-between">
                  <router-link
                      to="/group/edit"
                      class="btn btn-accent"
                  >
                    <span class="navbar-sec__link-subgroup-name">Создать новую группу</span>
                  </router-link>
                </li>
                <li class="display--flex justify-content-between">
                  <router-link :to="`/group/edit/${route.params.id}`"
                               class="btn btn-accent">Добавить в существующую группу
                  </router-link>
                </li>
              </ul>
            </template>
          </Popper>
        </div>
      </footer>
      <quotas-modal v-if="isShowModal" @close-modal="isShowModal = false" :checkedList="checkedList"
                    :appenedQuotas="appenedQuotas" :productOptions="productOptions"
      />
      <course-popup/>
      <pop-up-form title="Оформить заказ"
                   v-if="openConsultModal"
                   @closeModal="openConsultModal = false"/>
    </div>
  </div>
</template>

<script>

import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {computed, onBeforeMount, onMounted, reactive, watch} from "@vue/runtime-core";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";
import {ref} from "@vue/reactivity";
import Preloader from "@/components/Technical/Preloader.vue";
import QuotasModal from "./QuotasModal.vue";
import {AgGridVue} from "ag-grid-vue3";
import TablePagination from "../../components/OtherElements/TablePagination.vue";
import ControlVisibleColumns from "../../components/OtherElements/ControlVisibleColumns.vue";
import ButtonPass from "@/components/Tables/CellRenderers/ButtonPass.vue";
import {getTestStatus} from "../../feature/group-control/utils/groupPassingUtils";
import StatusRenderer from "@/components/Tables/CellRenderers/StatusRenderer.vue";
import TextLinkRenderer from "@/components/Tables/CellRenderers/TextLinkRenderer.vue";
import GroupTable from "../../feature/group-control/tables/GroupTable.vue";
import Popper from "vue3-popper";
import ClassRenderer from "@/components/Tables/CellRenderers/ClassRenderer.vue";
import CoursePopup from "../../feature/group-control/popups/CoursePopup.vue";
import productGroupForTestColumns from "./productGroupForTestColumns";
import productGroupForCorsesColumns from "./productGroupForCorsesColumns";
import PopUpForm from "@/components/Popups/PopUpForm.vue";
import CustomSelect from "@/components/Forms/Fields/CustomSelect.vue";
import useCheckRights from "@/mixins/useCheckRights";
import {notify} from "@kyvg/vue3-notification";


export default {
  components: {
    CustomSelect,
    PopUpForm,
    CoursePopup,
    Popper,
    ControlVisibleColumns,
    TablePagination,
    AgGridVue,
    QuotasModal,
    Preloader,
    ButtonPass,
    StatusRenderer,
    TextLinkRenderer,
    GroupTable,
    ClassRenderer
  },
  name: "product-group-report",

  setup() {
    const route = useRoute(),
        store = useStore(),
        {getRight} = useCheckRights(),
        isPreloaderLoad = ref(false),
        showAllUsers = ref(false),
        reportType = ref(null),
        groupName = ref(null),
        checkedList = ref([]),
        columnsName = ref(null),
        productCountFree = ref(0),
        productCountIssued = ref(0),
        productCountFinished = ref(0),
        isShowModal = ref(false),
        appenedQuotas = ref(false),
        groupUsers = ref([]),
        productOptions = ref([]),
        testOptions = ref([]),
        openConsultModal = ref(false),
        courseName = ref(null),
        productGroup = computed(() => store.state.group.productGroup),
        showAnalyticsBtn = computed(() => route.params.product === 'standard' && productCountFinished.value !== 0 || route.params.product === 'full' && productCountFinished.value !== 0),
        userQuotas = computed(() => store.state.company.userQuotas),
        isTestProductPage = computed(() => route.params.product === "standard" || route.params.product === "full" || route.params.product === "empty")

    let gridApi = ref(null),
        columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
      columnsName.value = "usersTableColumns";
    };

    const countOnPage = ref(localStorage.getItem("countOnPagePassings") || 10)

    const rowData = computed(() => {
      return groupUsers.value.map((user) => {
        return {
          id: user.id,
          name: user.name,
          status: user.test_passings ? getTestStatus(user) : user.course_passing ? user.course_passing.status : 'Не выдан',
          passingDate: user.last_success_test_passing_date ? user.last_success_test_passing_date : '',
          class: user.trajectories && user.course_passings ? [user.course_passings, user.trajectories] : "",
          testOptions: testOptions.value,
          coursePassing: user.course_passing,
          report: user.test_passings
              ? reportTranslate[user.test_passings.report_type]
              : "",
          code: user.test_passings ? user.test_passings.code : "",
        }
      })
    })

    const showModalAppened = () => {
      isShowModal.value = true
      appenedQuotas.value = true
    }
    const showModalRemove = () => {
      isShowModal.value = true
      appenedQuotas.value = false
    }

    const showConsultModal = () => {
      openConsultModal.value = !openConsultModal.value
    }

    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
      resizable: true,
      wrapText: true
    };
    const columnDefs = ref([])

    const setColumnDef = () => {
      if (isTestProductPage.value) {
        gridApi.value.setColumnDefs(productGroupForTestColumns.value);
      } else {
        gridApi.value.setColumnDefs(productGroupForCorsesColumns.value);
      }
    }
    const onFirstDataRendered = () => {
      setColumnDef()
    }
    const onRowSelected = (e) => {
      const id = e.node.data.id;
      const isInclude = checkedList.value.includes(id);
      if (e.node.isSelected() && !isInclude) {
        checkedList.value.push(id);
        return;
      }
      if (!e.node.isSelected() && isInclude) {
        checkedList.value = checkedList.value.filter(
            (currentId) => currentId != id
        );
      }
    };

    const morph = (int, product, array) => {
      if (product === "standard" || product === "full") {
        return (array = array || ['тест', 'теста', 'тестов']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
      } else if (product === "trajectory") {
        return (array = array || ['траектория', 'траектории', 'траекторий']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
      } else if (product === "references") {
        return (array = array || ['эталонный профиль', 'эталонных профиля', 'эталонных профилей']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
      } else {
        return (array = array || ['курс', 'курса', 'курсов']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
      }
    }

    onBeforeMount(() => {
      store.dispatch("group/getProductGroup", {product: route.params.product, id: route.params.id})
      store.dispatch("company/getUserQuotas").then(() => {
        for (let test in userQuotas.value.test_passings) {
          productOptions.value.push({
            id: test,
            name: reportTranslate[test],
            countFree: userQuotas.value.test_passings[test].free,
            countIssued: userQuotas.value.test_passings[test].issued,
          })
          testOptions.value.push({
            id: test,
            text: reportTranslate[test],
          })
        }
        userQuotas.value.course_passings?.total.forEach((course) => {
          productOptions.value.push({
            id: course.course_id,
            name: course.name,
            countFree: userQuotas.value.course_passings?.free.find((element) => element.course_id == course.course_id)?.count || 0,
            countIssued: userQuotas.value.course_passings?.issued.find((element) => element.course_id == course.course_id)?.count || 0
          })
        })
        if (userQuotas.value.reference_profiles) {
          productOptions.value.push({
            id: "reference_profiles",
            name: "Эталонный профиль",
            countFree: userQuotas.value.reference_profiles.count
          })
        }
        if (userQuotas.value.trajectories) {
          productOptions.value.push({
            id: "trajectory",
            name: "Траектории",
            countFree: userQuotas.value.trajectories.count
          })
        }
      })
    })

    const getReports = () => {
      const codes = [];
      gridApi.value.forEachNode((node) => {
        if (node.data.status == "Тест завершен" || node.data.reportLink) {
          codes.push(node.data.code);
        }
      });
      if (!codes.length) {
        notify({
          type: "warning",
          title: "Нет доступных для скачивания отчетов",
        });
        return;
      }
      store.dispatch("group/getReportsArchive", {
        group: route.params.id,
        name: groupName.value,
        codes,
      });
    };

    const remindAboutTest = () => {
      store.dispatch("group/remindAboutTest",{
        id: route.params.id,
        staffIds: checkedList.value,
      })
    }

    watch(
        () => [route.params.id, route.params.product],
        () => {
          if (route.params.id) {
            store.dispatch("group/getProductGroup", {product: route.params.product, id: route.params.id})
            checkedList.value = []
            setColumnDef()
          }
        }
    )

    watch(countOnPage, () => {
      localStorage.setItem("countOnPagePassings", countOnPage.value);
    });

    watch(isShowModal, () => {
      store.dispatch("group/getProductGroup", {product: route.params.product, id: route.params.id})
      if (isShowModal.value == false) {
        checkedList.value = []
      }
    });

    watch(productGroup, () => {
      courseName.value = userQuotas.value?.course_passings.total.find(course => course.course_id === route.params.product)?.name
      groupUsers.value = productGroup.value.quotas.users
      groupName.value = productGroup.value.quotas.group_name
      productCountFinished.value = productGroup.value.quotas.total?.finished
      productCountFree.value = productGroup.value.product_count?.free
      productCountIssued.value = productGroup.value.product_count?.issued
      reportType.value = route.params.nameProduct ? route.params.nameProduct : reportTranslate[route.params.product]
    })

    return {
      isPreloaderLoad,
      showAllUsers,
      reportType,
      groupName,
      groupUsers,
      checkedList,
      productCountFree,
      productCountIssued,
      route,
      isShowModal,
      showModalAppened,
      showModalRemove,
      appenedQuotas,
      gridApi,
      columnApi,
      rowData,
      defaultColDef,
      columnDefs,
      onGridReady,
      onRowSelected,
      countOnPage,
      columnsName,
      productOptions,
      onFirstDataRendered,
      morph,
      openConsultModal,
      showConsultModal,
      productCountFinished,
      showAnalyticsBtn,
      productGroup,
      courseName,
      getRight,
      getReports,
      isTestProductPage,
      remindAboutTest
    }
  }
}
</script>
<style lang="scss">
.product-group-table {
  .ag-body-viewport {
    min-height: 360px;
  }
}
</style>

<style scoped lang="scss">

.bg-white {
  background-color: #ffffff;
}

.group-header {
  @media (max-width: 425px) {
    flex-direction: column;
  }
}

.group-name {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  @media (max-width: 425px) {
    padding-bottom: 10px;
  }
}

.report-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
}

.about-box {
  &__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 5px;
  }
}

.btn-add {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 84%;
}

.form-group {
  min-width: 350px;
}

.clear-btn {
  margin-left: 15px;
}

.sort-btn {
  height: 45px;
  width: 45px;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 17px;

  &::before {
    content: "\f161";
    font-family: "Font Awesome 5 Pro";
    font-size: 17px;
  }
}

.mobile-sort {
  display: none;
}

.sort-value {
  padding-left: 40px;
  position: relative;

  &.active::before {
    content: "\f00c";
    position: absolute;
    left: 15px;
    top: 6px;
    font-family: "Font Awesome 5 Pro";
  }
}

.sort-line {
  border-bottom: 1px solid var(--cream-color);
  padding-bottom: 10px;
  margin-top: 15px;

  .clear-sort {
    padding: 0;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .sort-text.active {
    font-weight: 500;
    color: var(--main-color);
  }
}

footer {
  background-color: #2CC185;
  position: sticky;
  bottom: 62px;
}

.footer {
  &__box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
  }

  &__box-btn {
    margin: 5px;
  }

  @media (max-width: 800px) {
    &__box-btn {
      font-size: 13px;
    }
  }

  &__counter {
    color: white;
    padding-left: 21px;
    @media (max-width: 1150px) {
      display: none;
    }
  }

  &__counter_showOff {
    display: none;
  }

}

.warning-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #FF5C00;
  @media (max-width: 970px) {
    font-size: 14px;
  }
}

.ml-auto {
  margin-left: auto;
}


.btn-bg-white {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.btn-bg-white:hover {
  color: #575757;
}


@media (max-width: 1540px) {
  .staff-filters {
    flex-direction: column;
  }

  .clear-btn {
    align-self: flex-end;
    margin-top: 15px;
    margin-right: 15px;
  }
  .form-group {
    min-width: 100%;
  }
}

@media (max-width: 970px) {
  .sort-line {
    display: none;
  }
  .mobile-sort {
    display: flex;
  }
}

@media (max-width: 768px) {
  .options-ag {
    display: block;
  }
}
</style>